<template>
  <div class="relative block py-3 pl-2 pr-3 lg:hidden">
    <IconHamburger
      v-if="!isOpenMenu"
      class="text-xl text-red-500"
      @click.prevent="openMenu"
    />
    <IconClose v-else class="text-xl text-red-500" @click.prevent="openMenu" />
    <div
      v-if="isOpenMenu"
      class="bg-modal_dropdown fixed left-0 mt-2 h-full w-full"
    >
      <div class="menu-background absolute left-0 top-0 w-[160.48px]">
        <ul class="whitespace-nowrap px-4 text-sm">
          <li class="border-b border-gray-400 py-2">
            <div class="relative">
              <button
                class="relative flex w-full cursor-pointer items-center justify-between border-none py-3 text-sm lg:px-4"
              >
                <span class="mr-2">{{ t("language") }}</span>
                <div
                  class="mr-2 flex items-center justify-center uppercase"
                  @click.prevent="dropdownLang"
                >
                  <div class="mr-2 flex items-center">
                    <LanguagePicker :lang="configStore.lang">
                      <template #default="{ selectedLanguage }">
                        <component :is="selectedLanguage.icon" />
                        <span class="ml-1">{{ lang }}</span>
                      </template>
                    </LanguagePicker>
                  </div>
                  <IconDownArrow
                    class="transition-all"
                    :class="isOpenLang ? null : 'rotate-180'"
                  />
                </div>
              </button>
              <div v-if="isOpenLang" class="absolute right-0 z-50 shadow-md">
                <div class="w-[90px] rounded bg-white px-2 text-xs">
                  <LanguagePicker :lang="configStore.lang">
                    <template #default="{ availableLanguage }">
                      <div
                        v-for="language in availableLanguage"
                        :key="`language-option-${language.lang}`"
                        class="flex cursor-pointer items-center justify-center pb-2 pt-3 uppercase"
                        @click="configStore.lang = language.lang"
                      >
                        <component :is="language.icon" class="mr-2" />
                        {{ language.lang }}
                      </div>
                    </template>
                  </LanguagePicker>
                </div>
              </div>
            </div>
          </li>
          <li class="py-2">
            <a
              href="http://blog.hungryhub.com/hungryhub-for-business-eng/"
              rel="noopener noreferrer"
              >{{ t("forBusiness") }}</a
            >
          </li>
          <li class="py-2">
            <a href="https://blog.hungryhub.com" rel="noopener noreferrer">
              {{ t("blog") }}
            </a>
          </li>
          <li class="border-b border-gray-400 pb-3 pt-2">
            <nuxt-link to="">
              {{ t("giftCard") }}
            </nuxt-link>
          </li>
          <li class="py-2">
            {{ t("downloadApp") }}
            <a
              href="https://play.google.com/store/apps/details?id=com.hb.hungryhub"
              class="mb-2"
            >
              <HhImage
                :src="iconGooglePlay"
                :srcset="`${iconGooglePlay2x} 2x`"
                :use-mutator="true"
                alt="google logo"
                class="mt-2 w-full"
                :width="100"
              />
            </a>
            <a
              href="https://apps.apple.com/th/app/hungry-hub-dining-offer-app/id879303325"
              class="mb-2"
            >
              <HhImage
                :src="iconAppStore"
                :srcset="`${iconAppStore2x} 2x`"
                :use-mutator="true"
                alt="app store"
                class="mt-2 w-full"
                :width="100"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { ref } from "#imports";
import IconDownArrow from "~icons/hh-icons/icon-down-arrow";
import IconHamburger from "~icons/hh-icons/icon-hamburger";
import IconClose from "~icons/hh-icons/icon-times";
import HhImage from "~/components/HhImage.vue";
import iconGooglePlay from "~/assets/image/icon-google-play.jpg";
import iconGooglePlay2x from "~/assets/image/icon-google-play@2x.jpg";
import iconAppStore from "~/assets/image/icon-app-store.jpg";
import iconAppStore2x from "~/assets/image/icon-app-store@2x.jpg";
import useConfigStore from "~/stores/config";
import LanguagePicker from "~/components/LanguagePicker.vue";
const { t } = useI18n({
  useScope: "global",
});
const configStore = useConfigStore();
const { lang } = storeToRefs(configStore);

const isOpenMenu = ref(false);
const isOpenLang = ref(false);

function openMenu() {
  isOpenMenu.value = !isOpenMenu.value;
}

function dropdownLang() {
  isOpenLang.value = !isOpenLang.value;
}
</script>

<style lang="scss" scoped>
.bg-modal_dropdown {
  background: rgba(0, 0, 0, 0.5);
}

.menu-background {
  background: #ebeaea;
}
</style>
